import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyCCBQ-WvaEg8u4pjFxu_hn13sSPttobK0w",
    authDomain: "onzo-c33ea.firebaseapp.com",
    projectId: "onzo-c33ea",
    storageBucket: "onzo-c33ea.appspot.com",
    messagingSenderId: "1074491594631",
    appId: "1:1074491594631:web:2ddcd8d8f50806bc206522",
};

firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

// if (window.location.hostname === "localhost") {
//   projectFirestore.useEmulator("localhost", 8080);
//   projectAuth.useEmulator("http://localhost:9099/");
// }

export default firebase;
export { projectStorage, projectFirestore, projectAuth };
