import {
  Button,
  Container,
  makeStyles,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { projectAuth, projectFirestore } from "../config/firebase";
import { PollItemProps } from "../config/types";
import { ReactComponent as BattleSvg } from "../assets/imgs/battle.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import SignInDialog from "../dialogs/SignInDialog";
// import { formatDistanceToNow } from "date-fns";
// import { compareDesc } from "date-fns/esm";
import VoteDialog from "../dialogs/VoteDialog";
import { Spring } from "react-spring/renderprops-universal";
import Loading from "../comps/Loading";
import background from "../assets/imgs/ROG1.png";
import background1 from "../assets/imgs/Magu.png";
import mainlogo from "../assets/imgs/logo2.png";
import left from "../assets/imgs/left.png";
import right from "../assets/imgs/right.png";
import logo1 from "../assets/imgs/banner1.png";
import logo2 from "../assets/imgs/banner1.png";

const makeComponentStyles = makeStyles((theme) => ({
  matchPage: {
    display: "flex",
    flexDirection: "column",
    width: "200%",
    height: "103%",

  backgroundImage: `url(${background1})`,
                            backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",

        "& .main": {
          // backgroundColor: "rgba(156, 39, 176, .1)",
          backgroundImage: `url(${mainlogo})`,
          backgroundSize: "cover",
          marginTop: 0,
          width: "400px",
          height: "215px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        },


  "& .logo1": {
    // backgroundColor: "rgba(156, 39, 176, .1)",
    backgroundImage: `url(${logo1})`,
    backgroundSize: "cover",
    marginTop: 30,
    width: "686px",
    height: "111px",
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },

  

  "& .logo2": {
    // backgroundColor: "rgba(156, 39, 176, .1)",
    backgroundImage: `url(${logo2})`,
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",

  },

  "& .button": {
//background: "linear-gradient(#055C71, #22A6CA)",
   background: "linear-gradient(214deg, rgba(231,106,29,1) 0%, rgba(163,77,21,1) 52%, rgba(163,77,21,1) 100%)", 
    width: "25%",
    height: "30%",
    fontSize: "30px",
    border: "3px solid white",
    borderRadius: "10px",
    fontFamily: "'Press Start 2P'"
    
  },

	
  },
  match: {
    display: "flex",
    width: "100%",


    

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },

    

    "& .vs": { alignSelf: "center", height: 75, width: 75, margin: 10 },

    "& .left": {
      // backgroundColor: "rgba(156, 39, 176, .1)",
      backgroundImage: `url(${left})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: 373,
    },
    "& .right": {
      // backgroundColor: "rgba(244, 67, 54, .10)",
      backgroundImage: `url(${right})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: 373,

    },
	"& .details1": {
      fontSize: 120,
	  fontFamily: "The Neue",
	  marginTop: 100,
    color: "white",
},
    "& .team": {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexShrink: 0,
      padding: theme.spacing(8),
      margin: 40,
      borderRadius: 10,
      [theme.breakpoints.down("md")]: {
        margin: "2%",
      },

      "& .logo": {
        width: 300,
        height: 200,
        filter: "drop-shadow(0 8px 8px rgba(0,0,0,.2))",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginBottom: 10,
      },
      "& .org": {
        position: "relative",
        top: "85px",
        textTransform: "uppercase",
        textAlign: "center",
		fontFamily: "'Press Start 2P'",
        fontSize: 18,
        color: "#49A062",
        whiteSpace: "nowrap",

        
      },

      "& .org1": {
        position: "relative",
        top: "85px",
        textTransform: "uppercase",
        textAlign: "center",
		fontFamily: "'Press Start 2P'",
        fontSize: 18,
        color: "#D64B45",
        whiteSpace: "nowrap",

        
      },

      "& .school": {
        lineHeight: 1,
        textAlign: "center",
      },
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  comparisonBar: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 40px",
    width: "70%",
    marginLeft: 188,

    [theme.breakpoints.down("md")]: {
      padding: "20px 2%",
    },
  	          "& .team2votes": {
                fontFamily: "'Press Start 2P'",
          fontSize: 25,
		  color: "white",
          position: "absolute",
		  padding: theme.spacing(1.5, 55),
      zIndex: 999,
        },
    "& .bar": {
      height: 50,
      backgroundColor: "#A34D15",
      border: "3px solid white",
      borderRadius: "10px",
      "& .result": {
        height: "100%",
        width: "100%",
        //marginTop: 1,
        backgroundColor: "#E76A1D",
        transition: "0.6s cubic-bezier(0.33, 1, 0.68, 1)",
        borderRadius: "7px",
        zIndex: 888
      },

		"& .team1votes": {
      fontFamily: "'Press Start 2P'",
          fontSize: 25,
		  color: "white",
          position: "absolute",
		  padding: theme.spacing(1.5, 5),
      zIndex: 999,
        },
		        "& .team2votes": { right: 0 },
            
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 40,

    "& .expiry": {
      color: "white",
      fontFamily: "'Press Start 2P'",
      fontSize: 30,
      textAlign: "center",
      textTransform: "uppercase",
    },

    "& .tournament": {
      "& .value": {
        cursor: "pointer",
      },
    },

    "& .detail": {
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
      alignItems: "center",

      "& .caption": {
        color: "white",
        fontFamily: "'Press Start 2P'",
        fontSize: 30,
    textAlign: "center",
    textTransform: "uppercase",
      },

      "& .value": {
        fontSize: 50,
        fontFamily: "The neue",
        textAlign: "center",
        textTransform: "uppercase",
        color: "white",
        filter: "drop-shadow(0 8px 8px rgba(0,0,0,.2))",
        
      },
    },
  },
}));

const Match: React.FC<
  RouteComponentProps<{ tournamentId: string; matchId: string }>
> = ({ match: { params }, location: { pathname } }) => {
  const classes = makeComponentStyles();
  // const tournamentDocRef = projectFirestore.doc(
  //   `tournaments/${params.tournamentId}`
  // );
  const docRef = projectFirestore.doc(
    `tournaments/${params.tournamentId}/poll/${params.matchId}`
  );
  const [poll, loading] = useDocumentData<PollItemProps>(docRef);
  const [dialogState, setDialogState] = React.useState<boolean>(false);
  const [voteState, setVoteState] = React.useState<boolean>(false);
  const [user] = useAuthState(projectAuth);

  // totalvotes = team1_votes + team2_votes
  // leftteam% = (team1_votes / totalvotes) * 100

  const getPos = (): number => {
    let totalVotes: number =
      (poll?.team1_votes ?? 0) + (poll?.team2_votes ?? 0);
    let pos = ((poll?.team1_votes ?? 0) / totalVotes) * 100;

    return totalVotes > 0 ? pos : 50;
  };

  const pickEm = () => {
    if (!user) return setDialogState(true);
    setVoteState(true);
  };

  const gotoChallonge = () => {
    window.open(`https://www.oasisgaming.gg`);
  };

  return (
    <div className={classes.matchPage}style={{
                            
                          }}
                        >
                      

      {loading ? (
        <Loading />
      ) : poll && poll.is_published ? (
        <Spring
          from={{ opacity: 0, transform: "translateY(-15px)" }}
          to={{ opacity: 1, transform: "translateY(0px)" }}
        >
          {(props) => (
            <Container
              className={classes.container}
              maxWidth="lg"
              style={props}
            >

<div className="main"
                    style={{ backgroundImage: `url(${mainlogo})`}}>
                  </div>


              <div className={classes.match}>
                <div className="team left">
                  <div
                    className="logo"
                    style={{ backgroundImage: `url(${poll.team1?.logo})` }}
                  ></div>
                  <Typography variant="h4" className="org">
                    {poll.team1?.org_name}
                  </Typography>
                </div>
                <div className="details1"> </div>
                 
                <div className="team right">
                  <div
                    className="logo"
                    style={{ backgroundImage: `url(${poll.team2?.logo})` }}
                  ></div>
                  <Typography variant="h4" className="org1">
                    {poll.team2?.org_name}
                  </Typography>
                </div>
              </div>
              <div className={classes.comparisonBar}>
                
                <div className="bar">
				<div className="team2votes">
                                  {(poll?.team1_votes ?? 0) +
                                    (poll?.team2_votes ?? 0) ===
                                  0
                                    ? 50
                                    : Math.round(
                                        ((poll?.team2_votes ?? 0) /
                                          ((poll?.team1_votes ?? 0) +
                                            (poll?.team2_votes ?? 0))) *
                                          100
                                      )}
                                  %
                                </div>
                                <div className="team1votes">
                                  {(poll?.team1_votes ?? 0) +
                                    (poll?.team2_votes ?? 0) ===
                                  0
                                    ? 50
                                    : Math.round(
                                        ((poll?.team1_votes ?? 0) /
                                          ((poll?.team1_votes ?? 0) +
                                            (poll?.team2_votes ?? 0))) *
                                          100
                                      )}
                                  %
                                </div>
                  <div
                    className="result"
                    style={{
                      clipPath: `polygon(0% 0%, ${getPos()}% 0%, ${getPos()}% 100%, 0% 100%)`,
                    }}
                  >				 </div>

                </div>
              </div>

              

              <div className={classes.details}>
               {/*  <div className="tournament detail">
              <Typography variant="caption" className="caption">
                  
                  </Typography>
                 <Typography
                    variant="h5"
                    className="value"
                    onClick={gotoChallonge}
                  >
                    {poll.tournament_name}
                  </Typography>


                  
                </div>*/}

                {/* <div className="expiry">
                  {compareDesc(new Date(), poll.expiry_date_time.toDate()) === 1
                    ? `Closes in: ${formatDistanceToNow(
                        poll.expiry_date_time.toDate()
                      )}`
                    : `Closed ${formatDistanceToNow(
                        poll.expiry_date_time.toDate(),
                        { addSuffix: true, includeSeconds: true }
                      )}`}
                </div> */}
                {poll.is_closed && <div className="expiry">Poll is closed</div>}
                {!poll.vote_ids.includes(user?.uid ?? "") ? (
                  <Button
                  className="button"
                    variant="contained"
					color="secondary"
                    size="large"
                    onClick={pickEm}
                    disabled={
                      // compareDesc(
                      //   new Date(),
                      //   poll.expiry_date_time.toDate()
                      // ) === -1
                      poll.is_closed
                    }
                  >
                    VOTE
                  </Button>
                ) : (
                  <div className="tournament detail">
                  <Typography variant="caption" className="caption">
                    You have voted for{" "}
                    {
                      poll[
                        poll.votes.find((v) => v.id === user?.uid)?.vote ??
                          "team1"
                      ]?.org_name
                    }
                  </Typography>

                  
                  </div>
                  
                )}

<div className="logo1"
                    style={{ backgroundImage: `url(${logo1})` }} onClick={gotoChallonge}>
                  </div>
              </div>
              <SignInDialog
                open={dialogState}
                onClose={() => setDialogState(false)}
              />
              <VoteDialog
                open={voteState}
                onClose={() => setVoteState(false)}
                docRef={docRef}
                poll={poll}
              />
            </Container>
          )}
        </Spring>
      ) : (
        <div>This match does not exists</div>
      )}
    </div>
  );
};

export default withRouter(Match);
